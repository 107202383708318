export const paslaugosPage = [
  {
    title: '',
    paragraph: 'Centras teikia paslaugas, susijusias su  krašto tyrimais ir taikomąja veikla, vaikų ir jaunimo užimtumu, švietimu. Įstaigos veiklos spektras orientuotas į:',
    bulletpoints: [
      'gamtos bei kultūros paveldo tyrimus (paieška, identifikavimas, pagalba tyrėjams) ir išsaugojimą;',
      'naujų pažintinių maršrutų ir atmintinų vietų sudarymu;',
      'pažintinių-edukacinių išvykų organizavimą;',
      'jaunimo edukacinių, kultūros paveldo ir kraštortyros stovyklų plėtrą, naujų veiklų iniciatyvas;'
    ]
  },
  {
    title: 'Edukacija',
    paragraph: 'Vaikų ir jaunimo ugdymas apima tris veiklos kryptis:  kraštotyrinę, edukacinę ir turistinę, - tai yra visų jų simbiozė  turinti ribotus mokslinės krypties elementus. Mūsų  veiklos yra įvairialypės, visapusiškai ugdančios. Pagrindinis edukacinių veiklų tikslas - ugdyti iniciatyvią, aktyvią, atsakingą asmenybę. Edukacinės veiklos  išskirtinės tuo, kad ugdymas vyksta per asmeninę patirtį, saugioje aplinkoje patariamas ir lydimas profesionalų, specialistų.'
  },
  {
    title: 'Vasaros stovyklos',
    paragraph: 'Vaikų ir jaunimo vasaros stovyklos - tai puikus būdas turiningai praleisti vasaros atostogas. Mūsų organizuojamos stovyklos orientuotos į krašto pažinimą, tyrinėjimus, eksperimentavimą, turizmą. Vaikų užimtumu rūpinasi kūrybingi, patyrę pedagogai. Stovyklos vykdomos nedidelėmis grupėmis (iki 20 vaikų), o kasdienė programa sukurta taip, kad turėtų laiko ieškoti, pažinti, tyrinėti ir veikti.'
  }
]
