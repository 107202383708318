import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { PageSection, PageTextRectangle, PageBanner } from '../components'
import { StaticImage } from 'gatsby-plugin-image'
import { paslaugosPage } from '../assets/texts/paslaugos-page'

const Paslaugos = () => (
  <Layout>
    <Helmet>
      <title>Krasto pazinimas - paslaugos</title>
      <meta name="description" content="paslaugos" />
    </Helmet>
    
  <PageBanner title='Paslaugos'>
    <StaticImage
      className="page-banner-image"
      src="../assets/images/paslaugos_banner.jpg"
      alt="paslaugos"
      layout="fullWidth"
    />
  </PageBanner>
  
  {paslaugosPage.map(({ paragraph, title, bulletpoints}, index) => (
    // <PageSection key={`${index}-${title}`} className="section-4 content-page">
    <PageSection key={`${index}-${title}`} className="content-page services-page">
      <PageTextRectangle
        className='white-text-background'
        title={title}
        text={paragraph}
      />
  
      {bulletpoints && (
        // <div className='services-list'>
          <ul className='services-list'>
            {bulletpoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        // </div>
      )}
    </PageSection>
  ))}
</Layout>
)

export default Paslaugos
